import React, { useState } from "react";

const DeleteAccount = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        Eliminar cuenta
      </button>

      {showModal && (
        <form action="/api/v1/remove-account" method="post">
          <h3>Solicitar eliminación de cuenta</h3>

          <label>Nombre</label>
          <input type="text" name="name" placeholder="Nombre completo" />

          <label>Correo</label>
          <input type="text" name="email" placeholder="Correo electrónico" />

          <input type="submit" value="Enviar" />
        </form>
      )}
    </>
  );
};

export default DeleteAccount;
